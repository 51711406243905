const questionTree = [
	{
		label: '加速器使用问题',
		key: 'VPN_USAGE_PROBLEM',
		next: [
			{
				label: '安卓（手机端）',
				key: 'ANDROID_DEVICE',
				next: [
					{
						label: '华为鸿蒙看这里',
						key: 'HUAWEI_DEVICE',
						isFinal: true,
						additional: '附加说明，请尽可能详细描述您遇到的问题。',
						solution: '部分华为鸿蒙系统用户发现连接加速后无网络，' +
							'是因为升级鸿蒙系统后默认禁止了加速器的后台活动。\n解决方案：\n方法一：重装加速器App，请至官网下载最近版本（官网在主页菜单中）。' +
							'\n方法二：在系统设置中，找到启动管理/应用启动管理，关闭加速器App的自动管理开关，并在弹窗中勾选允许后台活动。'
					},
					{
						label: '网站/App无法访问',
						key: 'Web_APP_UNABLE_TO_ACCESS',
						next: [
							{
								label: '国内和国外网站/App都无法访问',
								key: 'WEB_APP_UNABLE_TO_ACCESS_BOTH',
								next: [
									{
										label: '以前正常，现在无法访问',
										key: 'USED_TO_BE_ABLE_TO_ACCESS',
										next: [
											{
												label: '百度可以访问',
												key: 'BAIDU_CAN_ACCESS',
												next: [
													{
														label: '可以正常打开谷歌',
														key: 'GOOGLE_CAN_ACCESS',
														additional: '请提供无法访问的网站/App。',
														isFinal: true,
														isRequired: true
													},
													{
														label: '打不开谷歌',
														key: 'GOOGLE_CANNOT_ACCESS',
														isFinal: true,
														additional: '请尽可能详细描述您遇到的问题。',
														isRequired: false
													}
												]
											},
											{
												label: '百度无法访问',
												key: 'BAIDU_CANNOT_ACCESS',
												additional: '请尽可能详细描述您遇到的问题。',
												isFinal: true,
												isRequired: false
											}
										]
									},
									{
										label: '一直都无法访问', key: 'NEVER_BE_ABLE_TO_ACCESS',
										next: [
											{
												label: '百度可以访问',
												key: 'BAIDU_CAN_ACCESS',
												next: [
													{
														label: '可以正常打开谷歌',
														key: 'GOOGLE_CAN_ACCESS',
														additional: '请提供无法访问的网站/App。',
														isFinal: true,
														isRequired: true
													},
													{
														label: '打不开谷歌',
														key: 'GOOGLE_CANNOT_ACCESS',
														isFinal: true,
														additional: '请尽可能详细描述您遇到的问题。',
														isRequired: false
													}
												]
											},
											{
												label: '百度无法访问',
												key: 'BAIDU_CANNOT_ACCESS',
												additional: '请尽可能详细描述您遇到的问题。',
												isFinal: true,
												isRequired: false
											}
										]
									}
								]
							},
							{
								label: '只是国外网站/App无法访问',
								key: 'WEB_APP_UNABLE_TO_ACCESS_FOREIGN',
								next: [
									{
										label: '以前正常，现在无法访问',
										key: 'USED_TO_BE_ABLE_TO_ACCESS',
										next: [
											{
												label: '百度可以访问',
												key: 'BAIDU_CAN_ACCESS',
												next: [
													{
														label: '可以正常打开谷歌',
														key: 'GOOGLE_CAN_ACCESS',
														additional: '请提供无法访问的网站/App。',
														isFinal: true,
														isRequired: true
													},
													{
														label: '打不开谷歌',
														key: 'GOOGLE_CANNOT_ACCESS',
														isFinal: true,
														additional: '请尽可能详细描述您遇到的问题。',
														isRequired: false
													}
												]
											},
											{
												label: '百度无法访问',
												key: 'BAIDU_CANNOT_ACCESS',
												additional: '请尽可能详细描述您遇到的问题。',
												isFinal: true,
												isRequired: false
											}
										]
									},
									{
										label: '一直都无法访问',
										key: 'NEVER_BE_ABLE_TO_ACCESS',
										additional: '连接加速器后，浏览器打开谷歌搜索是否可以访问https://google.com',
										next: [
											{
												label: '谷歌可以访问',
												key: 'GOOGLE_CAN_ACCESS',
												additional: '请提供无法访问的网站/App。',
												isFinal: true,
												isRequired: true
											},
											{
												label: '谷歌无法访问',
												key: 'GOOGLE_CANNOT_ACCESS',
												isFinal: true,
												additional: '请尽可能详细描述您遇到的问题。',
												isRequired: false
											}
										]
									}
								]
							},
							{
								label: '某个网站无法访问',
								key: 'WEB_APP_UNABLE_TO_ACCESS_FOREIGN',
								next: [
									{
										label: '谷歌可以访问',
										key: 'GOOGLE_CAN_ACCESS',
										additional: '请提供无法访问的网站/App。',
										isFinal: true,
										isRequired: true
									},
									{
										label: '谷歌无法访问',
										key: 'GOOGLE_CANNOT_ACCESS',
										isFinal: true,
										additional: '请尽可能详细描述您遇到的问题。',
										isRequired: true
									}
								]
							},
						]
					},
					{
						label: '网站/App访问速度慢',
						key: 'Web_APP_ACCESS_SLOW',
						next: [
							{
								label: '所有网站\\App都慢',
								key: 'ALL_WEB_APP_ACCESS_SLOW',
								next: [
									{
										label: '以前不慢，只是现在慢',
										key: 'USED_TO_BE_FAST',
										additional: '附加说明：请描述您当前的访问速度，以及期望的访问速度。',
										isFinal: true,
										isRequired: true
									},
									{
										label: '一直都慢',
										key: 'NEVER_BE_FAST',
										isFinal: true,
										additional: '附加说明：请描述您当前的访问速度，以及期望的访问速度。',
										isRequired: true
									}
								]
							},
							{
								label: '某个网站\\App慢',
								key: 'SOME_WEB_APP_ACCESS_SLOW',
								next: [
									{
										label: '以前不慢，只是现在慢',
										key: 'USED_TO_BE_FAST',
										additional: '附加说明：请提供 1、访问慢的网站/App；2、请描述您当前的访问速度，以及期望的访问速度。',
										isFinal: true,
										isRequired: true
									},
									{
										label: '一直都慢',
										key: 'NEVER_BE_FAST',
										isFinal: true,
										additional: '附加说明：请提供 1、访问慢的网站/App；2、请描述您当前的访问速度，以及期望的访问速度。',
										isRequired: true
									}
								]
							}
						]
					},
					{
						label: '加速器连接失败',
						key: 'VPN_CONNECT_FAILED',
						isFinal: true,
						additional: '请提供您看到的失败信息。',
						isRequired: true
					},
					{
						label: '安卓其他版本问题',
						key: 'ANDROID_OTHER_VERSION_PROBLEM',
						isFinal: true,
						additional: '安卓（手机端）请输入您遇到的问题或者意见反馈【如果属于无法访问/速度慢问题，请使用专属类目进行反馈，否则将不予处理】。',
						isRequired: true
					},
				]
			},
			{
				label: '苹果iOS（手机端）', key: 'IOS_DEVICE',
				isFinal: true,
				additional: '苹果手机正在上架审核中，敬请期待。或者您可以使用三方客户端作为备选方案，请访问仪表板查看使用说明：https://www.alphacloud.cc/。如有其他问题可在下方反馈。',
				isRequired: true
			},
			{
				label: 'Windows（电脑端）',
				key: 'WINDOWS_DEVICE',
				next: [
					{
						label: '加速器启动失败',
						key: 'VPN_START_FAILED',
						isFinal: true,
						additional: '请提供启动失败看到的错误信息。',
						solution: '亲爱的用户，如果您发现pc客户端无法连接，请按照下面的项目排查：\n' +
							'1、关闭所有杀毒软件；\n' +
							'2、如果电脑系统时间不准，一定要校准；\n' +
							'3、卸载\\禁用浏览器代理插件（如果用的谷歌浏览器）；\n' +
							'4、尝试右键以管理员身份运行加速器。',
						isRequired: true
					},
					{
						label: '网页无法访问',
						key: 'WEB_APP_UNABLE_TO_ACCESS_FOREIGN',
						next: [
							{
								label: '国内和国外网站都无法访问',
								key: 'ALL_WEB_APP_UNABLE_TO_ACCESS_FOREIGN',
								next: [
									{
										label: '以前正常，现在无法访问',
										key: 'USED_TO_BE_ABLE_TO_ACCESS',
										next: [
											{
												label: '百度可以访问',
												key: 'BAIDU_CAN_ACCESS',
												next: [
													{
														label: '可以正常打开谷歌',
														key: 'GOOGLE_CAN_ACCESS',
														additional: '请提供无法访问的网站/App。',
														isFinal: true,
														isRequired: true
													},
													{
														label: '打不开谷歌',
														key: 'GOOGLE_CANNOT_ACCESS',
														isFinal: true,
														additional: '请尽可能详细描述您遇到的问题。',
														isRequired: false
													}
												]
											},
											{
												label: '百度无法访问',
												key: 'BAIDU_CANNOT_ACCESS',
												additional: '请尽可能详细描述您遇到的问题。',
												isFinal: true,
												isRequired: false
											}
										]
									},
									{
										label: '一直都无法访问', key: 'NEVER_BE_ABLE_TO_ACCESS',
										next: [
											{
												label: '百度可以访问',
												key: 'BAIDU_CAN_ACCESS',
												next: [
													{
														label: '可以正常打开谷歌',
														key: 'GOOGLE_CAN_ACCESS',
														additional: '请提供无法访问的网站/App。',
														isFinal: true,
														isRequired: true
													},
													{
														label: '打不开谷歌',
														key: 'GOOGLE_CANNOT_ACCESS',
														isFinal: true,
														additional: '请尽可能详细描述您遇到的问题。',
														isRequired: false
													}
												]
											},
											{
												label: '百度无法访问',
												key: 'BAIDU_CANNOT_ACCESS',
												additional: '请尽可能详细描述您遇到的问题。',
												isFinal: true,
												isRequired: false
											}
										]
									}
								]
							},
							{
								label: '只是国外网站无法访问',
								key: 'ONLY_FOREIGN_WEB_APP_UNABLE_TO_ACCESS',
								next: [
									{
										label: '国外网站以前可以访问，现在不行了',
										key: 'USED_TO_BE_ABLE_TO_ACCESS',
										isFinal: true,
										additional: '如上面的方法仍未解决您的问题，请在下方尽可能详细的描述您遇到的问题。',
										solution: '尝试进行以下处理能否解决问题：\n1、关闭所有杀毒软件；\n2、如果电脑系统时间不准确，一定要校准；\n3、卸载\\禁用浏览器代理插件（如果用的谷歌浏览器）\n' +
											'4、尝试右键以管理员身份运行加速器',
										isRequired: true
									},
									{
										label: '一直都无法访问',
										key: 'NEVER_BE_ABLE_TO_ACCESS',
										additional: '连接加速器后，浏览器打开百度（https://baidu.com）是否可以访问',
										next: [
											{
												label: '可以正常打开百度',
												key: 'BAIDU_CAN_ACCESS',
												additional: '连接加速器后，浏览器打开谷歌搜索是否可以访问https://google.com',
												next: [
													{
														label: '可以正常打开谷歌',
														key: 'GOOGLE_CAN_ACCESS',
														additional: '您说的无法访问是指哪个网站？',
														isFinal: true,
														isRequired: true
													},
													{
														label: '无法打开谷歌',
														key: 'GOOGLE_CANNOT_ACCESS',
														isFinal: true,
														additional: '如上面的方法仍未解决您的问题，请在下方尽可能详细的描述您遇到的问题。',
														solution: '尝试进行以下处理能否解决问题：\n1、关闭所有杀毒软件；\n2、如果电脑系统时间不准确，一定要校准；\n3、卸载\\禁用浏览器代理插件（如果用的谷歌浏览器）\n' +
															'4、尝试右键以管理员身份运行加速器',
														isRequired: true
													}
												]
											},
											{
												label: '无法打开百度',
												key: 'BAIDU_CANNOT_ACCESS',
												isFinal: true,
												additional: '请尽可能详细描述您遇到的问题。',
												isRequired: false
											}
										]
									}
								]
							},
							{
								label: '某个网站无法访问',
								key: 'WEB_APP_UNABLE_TO_ACCESS_FOREIGN',
								next: [
									{
										label: '谷歌可以访问',
										key: 'GOOGLE_CAN_ACCESS',
										additional: '请提供无法访问的网站名称或网址。',
										isFinal: true,
										isRequired: true
									},
									{
										label: '谷歌无法访问',
										key: 'GOOGLE_CANNOT_ACCESS',
										additional: '请提供无法访问的网站名称或网址。',
										isFinal: true,
										isRequired: true
									}
								]
							},
						]
					},
					{
						label: '网站访问速度慢',
						key: 'WEB_APP_ACCESS_SLOW',
						next: [
							{
								label: '所有网站都慢',
								key: 'ALL_WEB_APP_ACCESS_SLOW',
								additional: '附加说明：请提供 1、访问慢的网站/App；2、请描述您当前的访问速度，以及期望的访问速度。',
								isFinal: true,
								isRequired: true
							},
							{
								label: '某个网站慢',
								key: 'SOME_WEB_APP_ACCESS_SLOW',
								next: [
									{
										label: '之前不慢，只是现在慢',
										key: 'USED_TO_BE_SLOW',
										additional: '附加说明：请提供 1、访问慢的网站/App；2、请描述您当前的访问速度，以及期望的访问速度。',
										isFinal: true,
										isRequired: true
									},
									{
										label: '一直都慢',
										key: 'ALWAYS_SLOW',
										isFinal: true,
										additional: '附加说明：请提供 1、访问慢的网站/App；2、请描述您当前的访问速度，以及期望的访问速度。',
										isRequired: true
									}
								]
							}
						]
					},
					{
						label: 'Window其他版本问题',
						key: 'WINDOW_OTHER_VERSION_PROBLEM',
						solution: '亲爱的用户，如果您发现pc客户端无法连接，请按照下面的项目排查：\n' +
							'1、关闭所有杀毒软件；\n' +
							'2、如果电脑系统时间不准，一定要校准；\n' +
							'3、卸载\\禁用浏览器代理插件（如果用的谷歌浏览器）；\n' +
							'4、尝试右键以管理员身份运行加速器。',
						additional: 'Windows（电脑端）请输入您遇到的问题或者意见反馈【如果属于无法访问/速度慢问题，请使用专属类目进行反馈，否则将不予处理】。',
						isFinal: true,
						isRequired: true
					}
				]
			},
			{
				label: 'Mac苹果（电脑端）',
				key: 'MAC_DEVICE',
				solution: 'Mac苹果电脑目前正在等待苹果审核，敬请期待。或者您可以使用三方客户端作为备选方案，请访问仪表板查看使用说明：https://www.alphacloud.cc/',
				additional: '如有其他问题，请在下方反馈。',
				isFinal: true,
				isRequired: true
			}
		]
	},
	{
		label: '账户问题',
		key: 'ACCOUNT_PROBLEM',
		next: [
			{
				label: '找回密码',
				key: 'FIND_PASSWORD',
				solution: '请在本App主界面【菜单】-【账户信息】中重置您的密码。',
				additional: '如上面的法方未解决您的问题，请在下方反馈。',
				isFinal: true,
				isRequired: true
			},
			{
				label: '多设备使用限制',
				key: 'MULTIPLE_DEVICE_LIMIT',
				solution: 'VIP会员可同时登录和使用4台设备。您可在设备管理中登出其他设备。',
				additional: '如上面的法方未能解答您的疑问，请在下方填写您的问题。',
				isFinal: true,
				isRequired: true
			}
		]
	},
	{
		label: '支付问题',
		key: 'PAYMENT_PROBLEM',
		next: [
			{
				label: '支付成功，VIP会员没有充上',
				key: 'PAYMENT_SUCCESS_VIP_NOT_ACTIVATED',
				additional: '请在支付宝中找到相应的支付账单，复制订单号，填入下方并提交',
				isFinal: true,
				isRequired: true
			},
			{
				label: '其他', key: 'OTHER_PAYMENT_PROBLEM', additional: '请尽可能详细描述您遇到的支付问题。',
				isFinal: true, isRequired: true
			},
		]
	},
	{
		label: '担心App被封？',
		key: 'WORRY_APP_BLOCKED_PROBLEM',
		additional: '1、我们的公司注册地在加拿大，符合当地政府的法律法规。\n2、加速器App有内置防封升级机制，如果App被技术封锁，会发升级通知更新到新版本。\n3、如果官网被技术封锁，新的官网地址会更新至“菜单”-“访问官网按钮”，通过点击该按钮可访问最新官网地址。\n4、VIP用户有单独的线路，有更高的防封机制。\n\n如果上方说明未能解答疑问，请在下方填写您的疑问：',
		isRequired: true
	},
	{
		label: '其他问题',
		key: 'OTHER_PROBLEM',
		additional: '请填写您遇到的问题【如果是加速器使用/找回密码/支付等问题，请使用专属类目进行反馈，否则将不予处理。】',
		isRequired: true
	}
]
const timezone = 'Asia/Shanghai';
export {
	questionTree,
	timezone
}
<template>
	<div class="image-view-container">
		<img src="../assets/close.svg" alt="close" class="image-viewer-close" @click="this.onCloseClicked()">
		<img :src="this.imageUrl" alt="image" class="image-view-img">
	</div>
</template>
<script>
export default {
	name: "ImageViewer",
	components: {},
	props: ['imageUrl'],
	data() {
	},
	methods: {
		onCloseClicked() {
			this.$emit('close');
		}
	}
}
</script>
<style scoped>
.image-view-container{
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.4em;
	box-sizing: border-box;
	backdrop-filter: blur(4px);
	top: 0;
	left: 0;
}

.image-view-img{
	max-width: 100%;
	max-height: 80%;
}

.image-viewer-close{
	width: 3em;
	position: fixed;
	right: 1em;
	top: 1em;
	cursor: pointer;
}
</style>
<template>
	<div class="main-container">
		<div class="container">
			<div class="column">
				<h2>搜索引擎</h2>
				<div class="web-list-container">
					<div class="web-list-wrapper">
						<div class="web-list-container-item" @click="onRedirect('https://www.google.com/ncr')">
							<div class="site-icon">
								<img class="web-logo" src="../assets/logo/google.svg" alt="google">
							</div>
							谷歌 (google)
						</div>
						<div class="web-list-container-item" @click="onRedirect('https://www.bing.com/')">
							<div class="site-icon">
								<img class="web-logo" src="../assets/logo/bing.svg" alt="google">
							</div>
							必应 (Bing)
						</div>
						<div class="web-list-container-item" @click="onRedirect('https://www.yahoo.com/')">
							<div class="site-icon">
								<img class="web-logo" src="../assets/logo/yahoo.svg" alt="google">
							</div>
							雅虎 (Yahoo)
						</div>
					</div>

				</div>
				<h2>社交媒体</h2>
				<div class="web-list-container">
					<div class="web-list-wrapper">
						<div class="web-list-container-item" @click="onRedirect('https://www.facebook.com/')">
							<div class="site-icon">
								<img class="web-logo" src="../assets/logo/facebook.svg" alt="facebook">
							</div>
							脸书 (Facebook)
						</div>
						<div class="web-list-container-item" @click="onRedirect('https://telegram.org/')">
							<div class="site-icon">
								<img class="web-logo" src="../assets/logo/telegram.svg" alt="telegram">
							</div>
							电报 (Telegram)
						</div>
						<div class="web-list-container-item" @click="onRedirect('https://www.instagram.com/')">
							<div class="site-icon">
								<img class="web-logo" src="../assets/logo/instagram.svg" alt="instagram">
							</div>
							instagram
						</div>

						<div class="web-list-container-item" @click="onRedirect('https://x.com/')">
							<div class="site-icon">
								<img class="web-logo" src="../assets/logo/x.svg" alt="x">
							</div>
							X （推特）
						</div>
					</div>
				</div>
				<h2>视频网站</h2>

				<div class="web-list-container">
					<div class="web-list-wrapper">
						<div class="web-list-container-item" @click="onRedirect('https://www.youtube.com/')">
							<div class="site-icon"><img class="web-logo" src="../assets/logo/youtube.svg" alt="youtube">
							</div>
							油管 (Youtube)
						</div>
						<div class="web-list-container-item" @click="onRedirect('https://www.netflix.com/')">
							<div class="site-icon">
								<img class="web-logo" src="../assets/logo/netflix.svg" alt="netflix">
							</div>
							奈飞 (Netflix)
						</div>
						<div class="web-list-container-item" @click="onRedirect('https://www.tiktok.com/')">
							<div class="site-icon">
								<img class="web-logo" src="../assets/logo/tiktok.svg" alt="tiktok">
							</div>
							Tiktok
						</div>
						<div class="web-list-container-item" @click="onRedirect('https://www.twitch.tv/')">
							<div class="site-icon">
								<img class="web-logo" src="../assets/logo/twitch.svg" alt="twitch">
							</div>
							Twitch
						</div>
						<div class="web-list-container-item" @click="onRedirect('https://vimeo.com/')">
							<div class="site-icon">
								<img class="web-logo" src="../assets/logo/vimeo.svg" alt="vimeo">
							</div>
							Vimeo
						</div>
					</div>
				</div>
				<h2>电商网站</h2>
				<div class="web-list-container">
					<div class="web-list-wrapper">
						<div class="web-list-container-item" @click="onRedirect('https://www.amazon.com/')">
							<div class="site-icon"><img class="web-logo" src="../assets/logo/amazon.svg" alt="amazon">
							</div>
							亚马逊 (Amazon)
						</div>
						<div class="web-list-container-item" @click="onRedirect('https://www.ebay.com/')">
							<div class="site-icon"><img class="web-logo" src="../assets/logo/ebay.svg" alt="ebay"></div>
							ebay
						</div>
						<div class="web-list-container-item" @click="onRedirect('https://www.aliexpress.com/')">
							<div class="site-icon">
								<img class="web-logo" src="../assets/logo/aliexpress.svg" alt="aliexpress">
							</div>
							aliexpress
						</div>
						<div class="web-list-container-item" @click="onRedirect('https://www.etsy.com/')">
							<div class="site-icon"><img class="web-logo" src="../assets/logo/etsy.svg" alt="etsy"></div>
							Etsy
						</div>
					</div>
				</div>

				<h2>AI网站</h2>
				<div class="web-list-container">
					<div class="web-list-wrapper">
						<div class="web-list-container-item" @click="onRedirect('https://openai.com/')">
							<div class="site-icon"><img class="web-logo" src="../assets/logo/openai.svg" alt="openai">
							</div>
							OpenAI
						</div>
						<div class="web-list-container-item" @click="onRedirect('https://claude.ai/')">
							<div class="site-icon"><img class="web-logo" src="../assets/logo/claude.svg" alt="claude">
							</div>
							Claude
						</div>
						<div class="web-list-container-item" @click="onRedirect('https://www.notion.so/')">
							<div class="site-icon"><img class="web-logo" src="../assets/logo/notion.svg" alt="notion">
							</div>
							Notion AI
						</div>
						<div class="web-list-container-item" @click="onRedirect('https://poe.com/')">
							<div class="site-icon"><img class="web-logo" src="../assets/logo/poe.svg" alt="poe"></div>
							Poe
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "WebList",
	components: {},
	data() {
		return {}
	},
	methods: {
		onRedirect(url) {
			if (window.Android && typeof window.Android.onEvent === 'function') {
				// 触发自定义事件
				window.Android.onEvent('openBrowser', JSON.stringify({url: url}));
			} else if (window.chrome && window.chrome.webview) {
				window.chrome.webview.postMessage(JSON.stringify({
					type: 'openWebsite',
					url: url
				}));
			} else if (window.webkit && window.webkit.messageHandlers) {
        const message = {
          type: "openWebsite",
          url: url
        };
        window.webkit.messageHandlers.appInterface.postMessage(message);
      } else {
				window.open(url, '_blank');
			}
		}
	}
}
</script>

<style scoped>
body {
	font-family: Arial, sans-serif;
	margin: 0;
	padding: 0;
	background-color: #f0f0f0;
}

h1 {
	color: #333;
	margin: 0;
}

.container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	max-width: 1000px;
}

.column {
	flex: 1;
	min-width: 250px;
	background-color: transparent;
	border-radius: 10px;
	padding: 20px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

h2 {
	color: #e6d5b8;
	border-bottom: 2px solid #e6d5b8;
	padding-bottom: 10px;
}

ul {
	list-style-type: none;
	padding: 0;
}

li {
	margin-bottom: 15px;
}

a {
	color: #e6d5b8;
	text-decoration: none;
	display: flex;
	align-items: center;
}

a:hover {
	text-decoration: underline;
}

.site-icon {
	width: 24px;
	height: 24px;
	margin-right: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f0f0f0;
	border-radius: 50%;
	background-color: rgba(230, 213, 184, 0.25);
}

.web-list-container-item {
	color: #e6d5b8;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 8px 0;
	cursor: pointer;
}

.web-list-wrapper {
	display: flex;
	flex-direction: column;
	width: fit-content;
	align-items: start;
}

.web-list-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.site-icon i {
	font-size: 18px;
}

.web-logo {
	width: 16px;
	height: 16px;
}
</style>